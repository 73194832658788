<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-mps__sbs">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader include-sub @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="add" @click="addChildNode({id: 0, level: 0, name: '无'})" v-if="$hasPermission('mps:sbs:save')"></toolbar-button>
            <toolbar-button role="delete" @click="myDeleteHandle()" v-if="$hasPermission('mps:sbs:delete')"></toolbar-button>
            <toolbar-button name="从国标引入" @click="quoteEstGb()" v-if="$hasPermission('mps:sbs:quote')"></toolbar-button>
          </el-button-group>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <el-tooltip content="刷新名称路径" placement="top" effect="light">
              <el-button icon="el-icon-refresh" v-if="$hasPermission('mps:sbs:update')" @click="refreshHandle"></el-button>
            </el-tooltip>
            <el-tooltip content="全局排序" placement="top" effect="light">
              <el-button icon="el-icon-sort" v-if="$hasPermission('mps:sbs:update')" @click="refreshSortNoHandle"></el-button>
            </el-tooltip>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
        v-if="tableShow"
        ref="table"
        v-loading="tableLoading"
        :data="tableData"
        row-key="id"
        lazy
        :load="loadTableData"
        @selection-change="dataListSelectionChangeHandle"
        highlight-current-row
        border
        :max-height="maxTableHeight"
        style="width: 100%;"
        :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="code" label="工程或费用编码" width="160" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="名称" min-width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="wbsName" label="关联的分部分项" align="left" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tooltip :content="`${scope.row.wbsPnames ? scope.row.wbsPnames + '/' + scope.row.wbsName : scope.row.wbsName}`" placement="bottom" effect="light">
              <span style="color: #F9A726">{{ scope.row.wbsName }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="unitName" label="单位" align="center" width="80"></el-table-column>
        <!--<el-table-column prop="keyCode" label="全码" width="120">ss</el-table-column>-->
        <el-table-column prop="levelName" label="概算类型" align="center" width="100" show-overflow-tooltip>
          <template v-slot="{row}">
            <span v-if="row.level == 1" style="color:rgb(34, 11, 240);">部</span>
            <span v-if="row.level == 2" style="color:rgb(238, 197, 17);">项</span>
            <span v-if="row.level == 3" style="color:blueviolet;">目</span>
            <span v-if="row.level == 4" style="color:chocolate;">节</span>
            <span v-if="row.level >= 5" style="color:green;">细目</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="220">
          <template slot-scope="{row}">
            <table-button role="addChild" @click="addChildNode(row)" v-if="$hasPermission('mps:sbs:save')"></table-button>
            <table-button role="edit" @click="addOrUpdateHandle(row)" v-if="$hasPermission('mps:sbs:update')"></table-button>
            <table-button role="delete" @click="myDeleteHandle(row.id, row.pid)" v-if="$hasPermission('mps:sbs:delete')"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @close="closeDialogHandle" @addNode="addNodeCallback" @updateNode="updateNodeCallback"></add-or-update>
      <!-- 引用项目节范本（国标） -->
      <estgbmain-quote v-if="estgbmainQuoteVisible" ref="estgbmainQuote" @quoteSubmit="quoteSubmitHandle"></estgbmain-quote>
    </div>
  </el-card>
</template>

<script>
  // 【标段分项】
  import mixinViewModule from '@/mixins/view-module'
  import AddOrUpdate from './sbs-add-or-update'
  import EstgbmainQuote from './estgbmain-quote'

  export default {
    mixins: [mixinViewModule],
    data () {
      return {
        mixinViewModuleOptions: {
          deleteURL: '/mps/sbs',
          deleteWithRelatedFiles: false,
          deleteSuccessCallback: this.deleteSuccessCallback
        },
        dataForm: {
          prjId: '',
          contractId: '',
          subcontractorId: ''
        },
        // 存储已加载的节点的map
        treeNodeMap: new Map(),
        tableLoading: false,
        tableData: [],
        tableShow: true,
        estgbmainQuoteVisible: false,
        maxTableHeight: 800
      }
    },
    components: {
      AddOrUpdate,
      EstgbmainQuote
    },
    mounted () {
      this.$nextTick(() => {
        this.maxTableHeight = window.innerHeight - 160
      })
    },
    methods: {
      // 合同标段切换事件
      contractChange (data) {
        this.dataForm.prjId = data.prjId
        this.dataForm.contractId = data.contractId
        this.dataForm.subcontractorId = data.subcontractorId
        this.getDataList()
      },
      getDataList () {
        this.treeNodeMap = new Map()
        // 重置一些el-table缓存的变量
        // 此处如果不重置，可能会导致一些无法预料的情况
        // 例如：某些节点被展开过，刷新后依然展开，其中的数据是缓存的而不是最新的
        this.tableShow = false
        this.$nextTick(() => {
          this.tableShow = true
        })
        this.tableLoading = true
        this.$http.get('/mps/sbs/children',
          {
            params: {
              pid: 0,
              ...this.dataForm
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.tableData = res.data
          this.tableLoading = false
        }).catch(() => {
          return this.$message.error('出错了')
        })
      },
      // 全局排序
      refreshSortNoHandle () {
        this.$confirm('是否要运行全局排序?').then(() => {
          const loading = this.$loading({
            lock: true,
            text: '请稍候...',
            spinner: 'el-icon-loading',
            customClass: 'my-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.$http.put(`/mps/sbs/refreshSortNo`,this.dataForm).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: '已完成',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          }).finally(() => {
            loading.close()
          })
        }).catch()
      },
      // el-table懒加载节点
      loadTableData (data,node,resolve) {
        let pid = data.id
        // 将已加载的节点相关参数存入map，用于后续增加子节点、删除子节点时刷新父节点
        this.treeNodeMap.set(pid,{data,node,resolve})
        this.$http.get('/mps/sbs/children',
          {
            params: {
              'pid': pid,
              ...this.dataForm
            }
          }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data);
          // 如果子节点数量为0，则说明这一次的load是在删除了最后一个子节点后进行的，需要删除lazyTreeNodeMap中对应的数据
          // 否则按照el-table的基础行为，此子节点删除后依然会显示在table中，视图不会更新
          if (res.data.length == 0) {
            this.$set(this.$refs.table.store.states.lazyTreeNodeMap,pid,[])
          }
        }).catch(() => {
          return this.$message.error('出错了')
        })
      },
      addNodeCallback (pid) {
        this.refreshParentNode(pid)
      },
      updateNodeCallback (pid,isPidChanged) {
        if (isPidChanged) {
          // 父节点修改过，直接刷新列表
          this.getDataList()
        } else {
          this.refreshParentNode(pid)
        }
      },
      // 刷新父节点（只能刷新展开过的节点）
      refreshParentNode (pid) {
        let nodeInfo = this.treeNodeMap.get(pid)
        if (nodeInfo) {
          // 这个loading属性控制此节点在load时是否重新加载
          // 例：在删除某个子节点后，若父节点loading属性为false，则父节点重新加载后，被删除的子节点依然显示
          nodeInfo.node.loading = true
          nodeInfo.data.hasChildren = true // 设置父节点为非叶子节点，出现展开按钮
          this.loadTableData(nodeInfo.data,nodeInfo.node,nodeInfo.resolve)
        } else{
          this.getDataList()
        }
      },
      // 新增子节点
      async addChildNode(row) {
        // 最末级节点新增子节点时，先判断该节点是否已经有分项清单，有则不允许新增子节点
        if (row.isLeaf) {
          try {
            let {data: res} = await this.$http.get(`/mps/subBoq/isSubItemExist/${row.id}`)
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            if (res.data) {
              return this.$message.error('该分项下存在分项清单，无法添加下级节点。')
            }
          } catch (error) {
            return this.$message.error('出错了')
          }
        }
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.pid = row.id
          this.$refs.addOrUpdate.dataForm.parentName = row.name
          this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
          this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
          this.$refs.addOrUpdate.dataForm.subcontractorId = this.dataForm.subcontractorId

          this.$refs.addOrUpdate.dataForm.level = (row.level + 1)
          this.$refs.addOrUpdate.init()
        })
      },
      deleteSuccessCallback({id,pid}) {
        if (id && pid) {
          this.refreshParentNode(pid)
        } else {
          this.getDataList()
        }
      },
      // 删除
      myDeleteHandle (id,pid) {
        this.deleteHandle(id,
            {
              deleteSuccessCallbackArgs: {id, pid},
              promptMessage: '同时会删除下级节点',
              promptTitle: '确定进行[删除]操作?',
              autoQuery: false
            }
        )
      },
      // 新增 / 修改
      addOrUpdateHandle (row) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.id = row.id
          this.$refs.addOrUpdate.dataForm.contractId = row.contractId
          this.$refs.addOrUpdate.dataForm.subcontractorId = row.subcontractorId
          this.$refs.addOrUpdate.init()
        })
      },
      // 引用国标
      quoteEstGb () {
        if (this.tableData && this.tableData.length > 0) {
          return this.$message.error("数据已存在，不允许再次引用范本！")
        } else {
          this.estgbmainQuoteVisible = true
          this.$nextTick(() => {
            this.$refs.estgbmainQuote.init()
          })
        }
      },
      // 确认引用
      quoteSubmitHandle (gbMain,level) {
        this.estgbmainQuoteVisible = false
        const loading = this.$loading({
          lock: true,
          text: '正在从国标范本中复制...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.get('/mps/sbs/quoteEstGb',// 引用国标
          {
            params: {
              mainId: gbMain.id,
              level: level,
              ...this.dataForm
            }
          }
        ).then(({data: res}) => {
          loading.close()
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: `已引用复制完成！`,
            type: 'warning',
            duration: 2000,
          })
          this.getDataList()
        }).catch(() => {
          loading.close()
          return this.$message.error('出错了')
        })
      },
      // 递归刷新 pids,pnames
      refreshHandle () {
        this.$confirm('您确定要刷新所有标段分项的名称路径吗？').then(() => {
          const loading = this.$loading({
            lock: true,
            text: '请稍候...',
            spinner: 'el-icon-loading',
            customClass: 'my-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.$http.get(`/mps/sbs/redressNamePath`,
            {
              params: {
                ...this.dataForm
              }
            }
          ).then(({data: res}) => {
            if (res.code != 0) {
              return this.$alert(res.msg,'',{
                confirmButtonText: '关闭',
                type: "error"
              })
            }
            this.$alert('已全部刷新！','',{
              confirmButtonText: '关闭',
              type: "success"
            })
          }).finally(() => {
            loading.close()
          })
        }).catch()
      },
    }
  }
</script>
