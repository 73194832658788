<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-mps__estModel">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <project-select v-model="dataForm.prjId" :defaultSelect="true" placeholder="项目选择" @change="projectChange"></project-select>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.prjId" style="margin-top: -3px;">
            <toolbar-button role="add" @click="addChildNode({id: 0, level: 0, name: '无'})" v-if="$hasPermission('mps:estModel:save')"></toolbar-button>
            <toolbar-button role="delete" @click="myDeleteHandle()" v-if="$hasPermission('mps:estModel:delete')"></toolbar-button>
            <toolbar-button name="引用范本" @click="quoteEstGb()" v-if="$hasPermission('mps:estModel:quote')"></toolbar-button>
            <toolbar-button role="import" @click="importHandle" v-if="$hasPermission('mps:estModel:import')"></toolbar-button>
          </el-button-group>
          <el-button-group v-if="dataForm.prjId" style="margin-top: -3px;">
            <el-tooltip content="全局排序" placement="top" effect="light">  
              <el-button icon="el-icon-sort" v-if="$hasPermission('mps:estModel:update')" @click="refreshSortNoHandle"></el-button>
            </el-tooltip>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
          v-if="tableShow"
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          row-key="id"
          lazy
          :load="loadTableData"
          @selection-change="dataListSelectionChangeHandle"
          highlight-current-row
          border
          :max-height="tableHeight"
          style="width: 100%;"
          :row-style="{height: '0px'}"
          :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="code" label="工程或费用编码" width="160" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="名称" min-width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="unitName" label="单位" header-align="center" align="center" width="80"></el-table-column>
        <el-table-column prop="keyCode" label="全码" width="120"></el-table-column>
        <el-table-column prop="levelName" label="概算类型" header-align="center" align="center" width="100" show-overflow-tooltip>
          <template v-slot="{row}">
            <span v-if="row.level == 1" style="color:rgb(34, 11, 240);">部</span>
            <span v-if="row.level == 2" style="color:rgb(238, 197, 17);">项</span>
            <span v-if="row.level == 3" style="color:blueviolet;">目</span>
            <span v-if="row.level == 4" style="color:chocolate;">节</span>
            <span v-if="row.level >= 5" style="color:green;">细目</span>
          </template>
        </el-table-column>
        <el-table-column prop="autoCalculateIds" label="计算方式" header-align="center" align="center" width="100" :formatter="autoCalculateFormatter"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="220">
          <template slot-scope="{row}">
            <table-button role="addChild" @click="addChildNode(row)" v-if="$hasPermission('mps:estModel:save')"></table-button>
            <table-button role="edit" @click="addOrUpdateHandle(row.id)" v-if="$hasPermission('mps:estModel:update')"></table-button>
            <table-button role="delete" @click="myDeleteHandle(row.id, row.pid)" v-if="$hasPermission('mps:estModel:delete')"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @close="closeDialogHandle" @addNode="addNodeCallback" @updateNode="updateNodeCallback"></add-or-update>
      <!-- 引用项目节范本（国标） -->
      <estgbmain-quote v-if="estgbmainQuoteVisible" ref="estgbmainQuote" @quoteSubmit="quoteSubmitHandle"></estgbmain-quote>
      <!-- excel文件导入弹窗 -->
      <excel-import v-if="excelImportVisible" ref="excelImport" @refreshDataList="getDataList" url="/mps/estModel/import" thumbnail="import_model.jpg"></excel-import>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './estmodel-add-or-update'
import EstgbmainQuote from './estgbmain-quote'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        deleteURL: '/mps/estModel',
        deleteWithRelatedFiles: false,
        deleteSuccessCallback: this.deleteSuccessCallback
      },
      dataForm: {
        prjId: '',
      },
      // 存储已加载的节点的map
      treeNodeMap: new Map(),
      tableLoading: false,
      tableData: [],
      tableShow: true,
      estgbmainQuoteVisible: false,
      tableHeight: 500,
    }
  },
  components: {
    AddOrUpdate,
    EstgbmainQuote
  },
  mounted () {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 180;
    })
  },
  methods: {
    projectChange () {
      this.getDataList()
    },
    // 全局排序
    refreshSortNoHandle() {
      this.$confirm('是否要运行全局排序?').then(() => {
        const loading = this.$loading({
          lock: true,
          text: '请稍候...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.put(`/mps/estModel/${this.dataForm.prjId}/refreshSortNo`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: '已完成',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.getDataList()
            }
          })
        }).finally(() => {
          loading.close()
        })
      }).catch()
    },
    // el-table懒加载节点
    loadTableData(data, node, resolve) {
      let pid = data.id
      // 将已加载的节点相关参数存入map，用于后续增加子节点、删除子节点时刷新父节点
      this.treeNodeMap.set(pid, {data, node, resolve})
      this.$http.get(
          '/mps/estModel/children',
          {
            params: {
              'prjId': this.dataForm.prjId,
              'pid': pid,
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        resolve(res.data);
        // 如果子节点数量为0，则说明这一次的load是在删除了最后一个子节点后进行的，需要删除lazyTreeNodeMap中对应的数据
        // 否则按照el-table的基础行为，此子节点删除后依然会显示在table中，视图不会更新
        if (res.data.length == 0) {
          this.$set(this.$refs.table.store.states.lazyTreeNodeMap, pid, [])
        }
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    getDataList() {
      this.treeNodeMap = new Map()
      // 重置一些el-table缓存的变量
      // 此处如果不重置，可能会导致一些无法预料的情况
      // 例如：某些节点被展开过，刷新后依然展开，其中的数据是缓存的而不是最新的
      this.tableShow = false
      this.$nextTick(() => {
        this.tableShow = true
      })
      this.tableLoading = true
      this.$http.get(
          '/mps/estModel/children',
          {
            params: {
              prjId: this.dataForm.prjId,
              pid: 0,
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.tableData = res.data
        this.tableLoading = false
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    addNodeCallback(pid, row) {
      // 新增子节点后，将其父节点改为非叶节点，否则不出现展开按钮
      row.hasChildren = true
      this.refreshParentNode(pid)
    },
    updateNodeCallback(pid, isPidChanged) {
      if(isPidChanged){
        // 父节点修改过，直接刷新列表
        this.getDataList()
      }else{
        this.refreshParentNode(pid)
      }
    },
    // 刷新父节点（只能刷新展开过的节点）
    refreshParentNode(pid) {
      let nodeInfo = this.treeNodeMap.get(pid)
      if (nodeInfo) {
        // 这个loading属性控制此节点在load时是否重新加载
        // 例：在删除某个子节点后，若父节点loading属性为false，则父节点重新加载后，被删除的子节点依然显示
        nodeInfo.node.loading = true
        this.loadTableData(nodeInfo.data, nodeInfo.node, nodeInfo.resolve)
      } else if (pid == 0) {
        this.getDataList()
      }
    },
    // 新增子节点
    addChildNode (row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        // 此处将row传给子组件，作为新增后的回调方法的参数
        this.$refs.addOrUpdate.row = row
        this.$refs.addOrUpdate.dataForm.pid = row.id
        this.$refs.addOrUpdate.dataForm.parentName = row.name
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        this.$refs.addOrUpdate.dataForm.level = (row.level + 1)
        this.$refs.addOrUpdate.init()
      })
    },
    deleteSuccessCallback({id,pid}) {
      if (id && pid) {
        this.refreshParentNode(pid)
      } else {
        this.getDataList()
      }
    },
    // 删除
    myDeleteHandle (id, pid) {
      this.deleteHandle(id, {deleteSuccessCallbackArgs: {id,pid}, autoQuery: false})
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.init()
      })
    },
    // 引用国标
    quoteEstGb() {
      if (this.tableData && this.tableData.length > 0) {
        return this.$message.error("概算范本中已存在数据，无法引用！")
      } else {
        this.estgbmainQuoteVisible = true
        this.$nextTick(() => {
          this.$refs.estgbmainQuote.init()
        })
      }
    },
    // 确认引用
    quoteSubmitHandle(gbMain, level) {
      this.estgbmainQuoteVisible = false
      const loading = this.$loading({
        lock: true,
        text: '正在从国标范本中复制...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.get('/mps/estModel/quoteEstGb',// 引用国标
        {
          params: {
            prjId: this.dataForm.prjId,
            mainId: gbMain.id,
            level: level,
          }
        }
      ).then(({ data: res }) => {
        loading.close()
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: `已引用复制完成！`,
          type: 'warning',
          duration: 2000,
          onClose: () => {
            // do nothing
          }
        })
        this.getDataList()
      }).catch(() => {
        loading.close()
        return this.$message.error('出错了')
      })
    },
    autoCalculateFormatter(row, column, cellValue, index) {
      if (cellValue) {
        return '自动计算'
      } else {
        return '手动填写'
      }
    }
  }
}
</script>
